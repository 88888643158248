import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2Segment } from 'angulartics2';
import { AuthGuard } from '../../../auth-guard.service';
import { ApiRequests } from '../../../shared/shared-module/services/apiRequests.service';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { Constants } from '../../../shared/shared-module/utility/constants';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  @ViewChild('email') public email;
  @ViewChild('password') public password;
  public login_data = { email: '', password: '' };
  public loginPass = { show: false };
  public CommonErrorMsg = '';
  public rememberMe = false;
  public resetSucess = false;
  public isLoginLoading = false;

  constructor(
    public commonService: CommonService,
    public dataService: DataService,
    public router: Router,
    public apiRequest: ApiRequests,
    public angulartics2Segment: Angulartics2Segment,
    public authGuard: AuthGuard
  ) {}

  public ngOnInit() {
    if (this.dataService.getUserData()) {
      document.cookie = 'internal_employee=true';
      this.commonService.newHeadertrue = false;
      if (localStorage.getItem('zoomAuthCode')) {
        this.router.navigate(['my-account']);
      } else if (localStorage.getItem('google_cal_auth')) {
        localStorage.removeItem('google_cal_auth');
        this.router.navigate(['my-account']);
      } else {
        this.router.navigate(['projects']);
      }
    } else {
      this.commonService.newHeadertrue = true;
    }
    this.rememberMe = this.dataService.getAutoComplete();
  }

  public checkIfEmailValid(): boolean {
    return (
      this.email &&
      this.email.errors &&
      (this.email.touched || this.email.dirty)
    );
  }

  public ispasswordError(): boolean {
    return (
      (this.password.touched || this.password.dirty) && this.password.errors
    );
  }

  public passwordLengthCheck(): boolean {
    return (
      this.password && this.password.errors.minlength && !this.ispasswordError()
    );
  }

  public passwordRequiredCheck(): boolean {
    return this.password && this.password.errors.required;
  }

  public checkLoginValidity(): boolean {
    return (
      this.checkIfEmailValid() &&
      this.passwordLengthCheck() &&
      this.passwordRequiredCheck()
    );
  }

  public showPassword() {
    this.loginPass.show = !this.loginPass.show;
  }

  public loginClick() {
    this.commonService.trackSegmentEventWithBrowserAndUserInfo(
      Constants.eventAndDeweyCodes.signup_clicked.eventName,
      {},
      Constants.eventAndDeweyCodes.signup_clicked.deweyCode,
      null
    );
    this.login_data.email = this.login_data.email.toLowerCase();
    this.commonService.trackSegmentEventWithBrowserAndUserInfo(
      Constants.eventAndDeweyCodes.login_clicked.eventName,
      {},
      Constants.eventAndDeweyCodes.login_clicked.deweyCode,
      {
        ...this.commonService.setUserProperties(this.login_data)
      }
    );
    this.isLoginLoading = true;
    this.apiRequest.login_signup(this.login_data, 'login').subscribe(
      (data) => {
        this.resetSigninForm();
        this.resetSucess = false;
        if (this.rememberMe) {
          this.dataService.storeAutoComplete(
            this.rememberMe ? 'true' : 'false'
          );
        }
        this.commonService.resetSucess = false;
        this.commonService.newHeadertrue = false;
        this.commonService.notifyMyAccountDetail(data);
        this.commonService.trackSegmentEventWithBrowserAndUserInfo(
          Constants.eventAndDeweyCodes.login_successful.eventName,
          data?.data?.user ? data.data.user : {},
          Constants.eventAndDeweyCodes.login_successful.deweyCode,
          null,
          this.commonService.setUserProperties(data?.data?.user)
        );
        document.cookie = 'internal_employee=true';
        if (this.authGuard.inputedURL == '/projects') {
          this.router.navigate(['projects']);
        } else if (
          this.authGuard.inputedURL != '/projects' &&
          this.authGuard.inputedURL != undefined &&
          this.authGuard.inputedURL != ''
        ) {
          /*this.router.navigate([this.authGuard.inputedURL]);
          this.router.navigate(['/projects', 1403077, 'journey'], {
            queryParams: { journeyId: '2377940' }
          });*/
          window.location.href = `${window.location.origin}/#${this.authGuard.inputedURL}`;
        } else {
          this.router.navigate(['projects']);
        }
        this.authGuard.inputedURL = '';
        this.isLoginLoading = false;
      },
      (error) => {
        this.isLoginLoading = false;
        this.commonService.trackSegmentEventWithBrowserAndUserInfo(
          Constants.eventAndDeweyCodes.login_unsuccessful.eventName,
          {},
          Constants.eventAndDeweyCodes.login_unsuccessful.deweyCode,
          null,
          this.commonService.setUserProperties(this.login_data)
        );
        this.CommonErrorMsg = error.error;
      }
    );
  }

  public rememberMeClick() {
    this.rememberMe = !this.rememberMe;
  }

  public resetSigninForm() {
    this.login_data = { email: '', password: '' };
  }

  public checkAutoComplete() {
    if (this.rememberMe) {
      return 'on';
    } else {
      return 'off';
    }
  }

  public getresetVar() {
    setTimeout(() => {
      this.commonService.resetSucess = false;
    }, 7000);
    return this.commonService.resetSucess;
  }

  public removeErrMsg() {
    this.CommonErrorMsg = '';
  }
}
