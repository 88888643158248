<ng-container *ngIf="bMeetDataLoaded">
  <div
    class="mr-custom-modal"
    *ngIf="meetingKind !== 'offline' && (!isGoogleLoggedIn || !bmeetSigned)"
  >
    <div class="custom-modal-backdrop" (click)="closeMeetingModal()"></div>
    <div class="custom-modal-wrapper xl">
      <div class="custom-modal-body" style="min-height: 750px">
        <div class="close-popup" (click)="closeMeetingModal()">
          <img src="assets/images/remove-popup.svg" alt="" />
        </div>
        <div class="custom-modal-title">{{ title }} Meeting</div>
        <div *ngIf="!isMymeeting" class="mr-meeting-wrapper auto-flex">
          <div class="meeting-form-col w-100">
            <div class="meeting-platform">
              <div class="platform-title">Meeting Platform</div>
              <div class="platform-list">
                <div
                  class="platform-chip"
                  (click)="toggleMeetingProvider()"
                  [ngClass]="{ active: getActiveMeetingProvider() }"
                >
                  B Meet
                </div>
                <div
                  class="platform-chip"
                  (click)="toggleMeetingProvider()"
                  [ngClass]="{ active: !getActiveMeetingProvider() }"
                >
                  Zoom
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="zoomTemplate"></ng-container>
        <app-link-google-calendar
          (linkGoogleCalendarSuccess)="refreshGoogleLoggedIn()"
          class="create-bmeet-container"
          [ngClass]="{ 'create-bmeet-container': isBMeetPlatform }"
        >
          <div class="cal-icon" *ngIf="isBMeetPlatform">
            <img src="../../../../../assets/images/calendar-error.svg" alt="" />
          </div>
          <ng-container
            *ngIf="!isGoogleLoggedIn && bmeetSigned && isBMeetPlatform"
          >
            <div class="gcalendar">
              <h2>Link Google Calendar First</h2>
              Your Google Calendar is not linked.
              <a href="javascript: void()" (click)="googleCalenderLogin()"
                >Click here</a
              >
              &nbsp;to link your google calendar.
            </div>
          </ng-container>
          <ng-container
            *ngIf="!isGoogleLoggedIn && !bmeetSigned && isBMeetPlatform"
          >
            <div class="gcalendar">
              <ol>
                <li>
                  Your BMeet account is not created, please reach out to IT team
                  (email:
                  <a href="mailto:ithelpdesk@builder.ai"
                    >ithelpdesk&#64;builder.ai</a
                  >)
                </li>
                <li>
                  Your Google Calendar is not linked,
                  <a
                    href="javascript: void()"
                    (click)="googleCalenderLogin()"
                    rel="noopener noreferrer"
                    >Click here</a
                  >&nbsp;to link your google calendar.
                </li>
              </ol>
            </div>
          </ng-container>
          <ng-container
            *ngIf="isGoogleLoggedIn && !bmeetSigned && isBMeetPlatform"
          >
            <div class="gcalendar">
              <h2>Create BMeet Account First</h2>
              Your BMeet account is not created yet. Please reach out to IT team
              (email:
              <a href="mailto:ithelpdesk@builder.ai" rel="noopener noreferrer"
                >ithelpdesk&#64;builder.ai</a
              >) for the creation of BMeet account.
            </div>
          </ng-container>
        </app-link-google-calendar>
        <ng-container>
          <div class="custom-modal-btns">
            <button class="green-btn" (click)="okClicked()">Ok</button>
            <button
              class="primary-purple-btn is-outlined"
              (click)="closeMeetingModal()"
            >
              Cancel
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- *ngIf="meetingKind == 'offline' || (isGoogleLoggedIn && bmeetSigned)" -->
  <form
    *ngIf="meetingKind === 'offline' || (isGoogleLoggedIn && bmeetSigned)"
    [formGroup]="meetingForm"
    (keydown.enter)="$event.preventDefault()"
    (ngSubmit)="formSubmit()"
  >
    <div class="mr-custom-modal">
      <div class="custom-modal-backdrop" (click)="closeMeetingModal()"></div>
      <div class="custom-modal-wrapper xl">
        <div class="custom-modal-body">
          <em class="close-modal" (click)="closeMeetingModal()"
            ><img src="../../../../../assets/images/close-modal.svg" alt=""
          /></em>
          <div class="custom-modal-title">
            <ng-container *ngIf="meetingKind === 'offline'; else showTitle"
              >Log</ng-container
            >
            <ng-template #showTitle> {{ title }}</ng-template>
            Meeting
          </div>

          <div class="mr-meeting-wrapper">
            <div class="meeting-form-col">
              <div
                class="meeting-platform"
                *ngIf="meetingKind !== 'offline' && !isMymeeting"
              >
                <div class="platform-title">Meeting Platform</div>
                <div class="platform-list">
                  <div
                    class="platform-chip"
                    (click)="toggleMeetingProvider()"
                    [ngClass]="{ active: getActiveMeetingProvider() }"
                  >
                    B Meet
                  </div>
                  <div
                    class="platform-chip"
                    (click)="toggleMeetingProvider()"
                    [ngClass]="{ active: !getActiveMeetingProvider() }"
                  >
                    Zoom
                  </div>
                </div>
              </div>
              <ng-container *ngIf="isBMeetPlatform">
                <!-- buildcard only for my-meetings section -->
                <div
                  *ngIf="isMymeeting"
                  class="form-group mr-context-menu-wrp position-relative buildcard-form"
                >
                  <label> Buildcard </label>
                  <div class="input-dropdown-wrp">
                    <input
                      #searchField
                      type="text"
                      class="mr-input sm"
                      (keyup)="searchProject($event)"
                      [(ngModel)]="searchText"
                      placeholder="Search by Buildcard name"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <span
                      *ngIf="isShowPaginationLoader"
                      class="dropdown-loader spinner-border spinner-border-md"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <div
                    *ngIf="toggleProjectDropdown"
                    class="mr-context-menu abs-pos max-height w-100"
                    infiniteScroll
                    [infiniteScrollDistance]="1"
                    [infiniteScrollThrottle]="50"
                    (scrolled)="onScroll()"
                    [scrollWindow]="false"
                  >
                    <div
                      (click)="changeProject(project)"
                      *ngFor="let project of projectList"
                      class="user-groups"
                    >
                      <div
                        title="{{ project.name }}"
                        class="context-menu-item sm-y-pad"
                      >
                        <div
                          class="user-card has-checkbox"
                          [style.cursor]="'pointer'"
                        >
                          <div class="project-info">
                            <div class="project-name">{{ project.name }}</div>
                            <div
                              class="project-status"
                              [ngClass]="
                                project.status === 'pending'
                                  ? project.first_payment_date
                                    ? 'paid'
                                    : 'unpaid'
                                  : project.status
                              "
                            >
                              {{
                                project.status === 'pending'
                                  ? project.first_payment_date
                                    ? 'paid'
                                    : 'unpaid'
                                  : project.status === 'blocked'
                                    ? 'paused'
                                    : project.status === 'builder_care'
                                      ? 'builder care'
                                      : project.status
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container
                      *ngIf="
                        !isShowPaginationLoader && projectList.length === 0
                      "
                    >
                      <span
                        class="h-100 w-100 text-center d-flex no-record px-3 mt-5 mb-5"
                        >No build card found!</span
                      >
                    </ng-container>
                  </div>
                </div>

                <div class="form-group">
                  <label>Title</label>
                  <!-- <input type="text" class="mr-input sm" /> -->
                  <input
                    class="form-control mr-input sm"
                    formControlName="meetingName"
                    type="text"
                    name="meetingName"
                    value=""
                    placeholder="Enter name"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      (meetingName.touched && meetingName.invalid) ||
                      (meetingName.touched && meetingName.value.trim() === '')
                    "
                  >
                    Meeting Name is mandatory
                  </div>
                </div>

                <div
                  *ngIf="
                    editMeeting &&
                    editMeeting.studio_call_type === 'kickoff' &&
                    isAuthorisedUser &&
                    selectedPodData
                  "
                  class="form-group"
                >
                  <label for="Meeting Owner">Meeting Owner</label>
                  <meeting-custom-dropdown-list
                    class="custom-formcontrol"
                    [options]="selectedPodData.users"
                    customClass="w-100"
                    idPrefix="meeting_owner"
                    optionKeyStr="key"
                    optionValueStr="value"
                    formControlName="meeting_owner"
                  ></meeting-custom-dropdown-list>
                </div>

                <div class="form-group">
                  <div class="mr-meeting-inner-title">
                    <label>Start date & Time</label>
                    <app-time-zone
                      class="meeting-revamp-timezone"
                      [timezoneConfig]="timezoneConfig"
                      (sendSelectedTimeZone)="getSelectedTimezone($event)"
                    >
                    </app-time-zone>
                  </div>
                  <div class="date-time-row form-group position-relative">
                    <div class="date-col">
                      <div
                        *ngIf="myDatePickerOptions"
                        (click)="enddp.toggleCalendar()"
                      >
                        <input
                          autocomplete="off"
                          class="mr-input sm is-datepicker"
                          placeholder="Select date"
                          (dateChanged)="startDateChanged($event)"
                          angular-mydatepicker
                          name="meetingDate"
                          (keydown.enter)="
                            $event.stopPropagation(); enddp.toggleCalendar()
                          "
                          formControlName="meetingDate"
                          [options]="myDatePickerOptions"
                          #enddp="angular-mydatepicker"
                        />
                      </div>
                      <div
                        class="invalid-feedback"
                        style="margin-bottom: -16px"
                        *ngIf="
                          (meetingDate.touched && meetingDate.invalid) ||
                          (meetingDate.touched && meetingDate.value === '')
                        "
                      >
                        Start Date is mandatory
                      </div>
                    </div>
                    <meeting-custom-dropdown-list
                      class="custom-formcontrol"
                      [options]="fromTimeArr"
                      customClass="time-col"
                      idPrefix="from_time"
                      formControlName="meetingStartTime"
                      (selectEmit)="selectedFromTimeDropdown()"
                      [disabled]="isEditFormDisabled"
                    ></meeting-custom-dropdown-list>
                    <span class="text-col">to</span>
                    <meeting-custom-dropdown-list
                      class="custom-formcontrol"
                      [options]="toTimeArr"
                      customClass="time-duration-col"
                      idPrefix="to_time"
                      optionKeyStr="key"
                      optionValueStr="value"
                      formControlName="meetingEndTime"
                      [disabled]="isEditFormDisabled"
                    ></meeting-custom-dropdown-list>
                    <div
                      [(elemDetect)]="timezoneDropdownIsVisible"
                      class="timezone-col mr-context-menu-wrp"
                    >
                      <em
                        class="globe-icon"
                        (click)="
                          timezoneDropdownIsVisible = !timezoneDropdownIsVisible
                        "
                      >
                        <img
                          src="../../../../../assets/images/globe-black.svg"
                          alt=""
                      /></em>
                      <div
                        class="mr-context-menu max-height abs-pos left-aligned"
                        *ngIf="timezoneDropdownIsVisible"
                      >
                        <div class="context-menu-item">
                          {{
                            getSelectedTime()
                              | timezoneConversion
                                : timezoneConfig?.selectedData
                                : clientTimezone
                                : true
                          }}
                        </div>
                        <div class="context-menu-item">
                          {{
                            getSelectedTime()
                              | timezoneConversion
                                : timezoneConfig?.selectedData
                                : 'GMT'
                          }}
                        </div>
                        <div class="context-menu-item">
                          {{
                            getSelectedTime()
                              | timezoneConversion
                                : timezoneConfig?.selectedData
                                : 'BST'
                          }}
                        </div>
                        <div class="context-menu-item">
                          {{
                            getSelectedTime()
                              | timezoneConversion
                                : timezoneConfig?.selectedData
                                : 'CST'
                          }}
                        </div>
                        <div class="context-menu-item">
                          {{
                            getSelectedTime()
                              | timezoneConversion
                                : timezoneConfig?.selectedData
                                : 'GST'
                          }}
                        </div>
                        <div class="context-menu-item">
                          {{
                            getSelectedTime()
                              | timezoneConversion
                                : timezoneConfig?.selectedData
                                : 'IST'
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      editMeeting?.studio_call_type !== 'kickoff' &&
                      (meetingDate.touched || startTimeTouched) &&
                      !isStartTimeValid
                    "
                  >
                    Start time should be
                    {{ meetingKind === 'offline' ? 'less' : 'greater' }} than
                    current time in selected timezone
                  </div>
                  <ng-container
                    *ngIf="
                      isEndTimeValid
                        | applyFunc
                          : meetingDate?.value?.singleDate?.jsDate
                          : meetingForm.get('meetingStartTime')?.value
                          : meetingForm.get('meetingEndTime')?.value
                          : timezoneConfig?.selectedData
                        | async as endTimeValidValue
                    "
                  >
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        editMeeting?.studio_call_type !== 'kickoff' &&
                        !endTimeValidValue?.value
                      "
                    >
                      Meeting duration can’t be over 4 hours. Please try
                      scheduling for 4 hours or less.
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      meetingKind !== 'offline' &&
                      (editMeeting ? editRecurringType === 'all' : true)
                    "
                  >
                    <div class="meeting-repeat-row form-group mt-5">
                      <meeting-custom-dropdown-list
                        class="custom-formcontrol"
                        [options]="meetingRepeatOptions"
                        customClass="repeat-freq-col"
                        idPrefix="recurrence_type"
                        optionKeyStr="key"
                        optionValueStr="name"
                        formControlName="meetingRecurrenceType"
                        (selectEmit)="repeatMeetingSelected($event)"
                        [disabled]="isEditFormDisabled"
                      ></meeting-custom-dropdown-list>
                      <div
                        class="weekly-repeat-col"
                        *ngIf="
                          meetingForm.get('meetingRecurrenceType')?.value ===
                          'weekly'
                        "
                      >
                        <div class="weekly-label">Repeat every</div>
                        <input
                          type="number"
                          min="1"
                          max="10"
                          class="mr-input sm no-spinners"
                          formControlName="meetingRecurrenceRepeatWeek"
                          (input)="validateInput($event)"
                          (change)="updateOccurences()"
                        />
                        <div class="incr-decr-btns">
                          <div
                            class="inner-btn"
                            (click)="updateWeeklyRepeat('increment')"
                          >
                            <img
                              class="reverse"
                              src="../../../../../assets/images/filter-down-arrow.svg"
                              alt=""
                            />
                          </div>
                          <div
                            class="inner-btn"
                            (click)="updateWeeklyRepeat('decrement')"
                          >
                            <img
                              src="../../../../../assets/images/filter-down-arrow.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="weekly-label">week</div>
                      </div>
                      <div
                        *ngIf="
                          meetingForm.get('meetingRecurrenceType')?.value ===
                          'monthly'
                        "
                        class="weekly-repeat-col"
                      >
                        <div class="weekly-label">
                          Occurs On (Day of month)
                          <!-- <span>*</span> -->
                        </div>
                        <div class="select-box month-select-container">
                          <select
                            (change)="updateOccurences()"
                            class="form-control month-select"
                            formControlName="meetingRecurrenceMonthDay"
                            placeholder="Select Recurrence"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </select>
                        </div>
                      </div>
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          (meetingRecurrenceWeekDays?.touched &&
                            meetingRecurrenceWeekDays?.invalid) ||
                          (meetingRecurrenceMonthDay?.touched &&
                            meetingRecurrenceMonthDay?.invalid)
                        "
                      >
                        Occurs On is mandatory
                      </div>
                    </div>
                    <div
                      class="daily-repeat-row position-relative"
                      *ngIf="
                        meetingForm.get('meetingRecurrenceType')?.value !==
                        'single'
                      "
                    >
                      <div
                        *ngIf="
                          meetingForm.get('meetingRecurrenceType')?.value ===
                          'weekly'
                        "
                        class="daily-repeat-col"
                      >
                        <div class="daily-label">Repeat on</div>
                        <div class="days-list">
                          <div class="day-checkbox">
                            <input
                              tabindex="-1"
                              type="checkbox"
                              class="hidden-input"
                              (change)="weeklyReccurenceChange($event, 'sun')"
                              [checked]="
                                meetingForm
                                  .get('meetingRecurrenceWeekDays')
                                  .value.indexOf('sun') > -1
                              "
                            />
                            <span>S</span>
                          </div>
                          <div class="day-checkbox">
                            <input
                              tabindex="-1"
                              type="checkbox"
                              class="hidden-input"
                              (change)="weeklyReccurenceChange($event, 'mon')"
                              [checked]="
                                meetingForm
                                  .get('meetingRecurrenceWeekDays')
                                  .value.indexOf('mon') > -1
                              "
                            />
                            <span>M</span>
                          </div>
                          <div class="day-checkbox">
                            <input
                              tabindex="-1"
                              type="checkbox"
                              class="hidden-input"
                              (change)="weeklyReccurenceChange($event, 'tue')"
                              [checked]="
                                meetingForm
                                  .get('meetingRecurrenceWeekDays')
                                  .value.indexOf('tue') > -1
                              "
                            />
                            <span>T</span>
                          </div>
                          <div class="day-checkbox">
                            <input
                              tabindex="-1"
                              type="checkbox"
                              class="hidden-input"
                              (change)="weeklyReccurenceChange($event, 'wed')"
                              [checked]="
                                meetingForm
                                  .get('meetingRecurrenceWeekDays')
                                  .value.indexOf('wed') > -1
                              "
                            />
                            <span>W</span>
                          </div>
                          <div class="day-checkbox">
                            <input
                              tabindex="-1"
                              type="checkbox"
                              class="hidden-input"
                              (change)="weeklyReccurenceChange($event, 'thu')"
                              [checked]="
                                meetingForm
                                  .get('meetingRecurrenceWeekDays')
                                  .value.indexOf('thu') > -1
                              "
                            />
                            <span>T</span>
                          </div>
                          <div class="day-checkbox">
                            <input
                              type="checkbox"
                              class="hidden-input"
                              (change)="weeklyReccurenceChange($event, 'fri')"
                              [checked]="
                                meetingForm
                                  .get('meetingRecurrenceWeekDays')
                                  .value.indexOf('fri') > -1
                              "
                            />
                            <span>F</span>
                          </div>
                          <div class="day-checkbox">
                            <input
                              tabindex="-1"
                              type="checkbox"
                              class="hidden-input"
                              (change)="weeklyReccurenceChange($event, 'sat')"
                              [checked]="
                                meetingForm
                                  .get('meetingRecurrenceWeekDays')
                                  .value.indexOf('sat') > -1
                              "
                            />
                            <span>S</span>
                          </div>
                        </div>
                      </div>
                      <div class="daily-date-col">
                        <div class="daily-label">Ends On</div>
                        <div (click)="endDate.toggleCalendar()">
                          <input
                            autocomplete="off"
                            class="mr-input sm is-datepicker"
                            placeholder="Select date"
                            (dateChanged)="endDateChanged($event)"
                            (keydown.enter)="
                              $event.stopPropagation(); endDate.toggleCalendar()
                            "
                            name="meetingRecurrenceEndDate"
                            formControlName="meetingRecurrenceEndDate"
                            angular-mydatepicker
                            [options]="recurringEndDatePickoptions"
                            #endDate="angular-mydatepicker"
                          />
                        </div>
                        <div
                          *ngIf="recurOccurences.length"
                          class="occurences-count mx-2"
                        >
                          {{ recurOccurences.length }}
                          {{
                            recurOccurences.length > 1
                              ? 'Occurrences'
                              : 'Occurrence'
                          }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        meetingRecurrenceEndDate?.touched &&
                        meetingRecurrenceEndDate?.invalid
                      "
                    >
                      End Date is mandatory
                    </div>
                  </ng-container>
                </div>
                <div class="form-group" *ngIf="meetingKind === 'offline'">
                  <label>Call Type </label>
                  <meeting-custom-dropdown-list
                    class="custom-formcontrol"
                    [options]="callType"
                    customClass="call_type"
                    idPrefix="call_type"
                    [optionKeyStr]="'name'"
                    [optionValueStr]="'value'"
                    formControlName="callType"
                    [disabled]="isEditFormDisabled || editMeeting"
                  ></meeting-custom-dropdown-list>
                </div>

                <div *ngIf="meetingKind === 'offline'" class="form-group">
                  <label
                    >Recording URL
                    <!-- <span>*</span> -->
                  </label>
                  <input
                    [class.disabled]="isEditFormDisabled || editMeeting"
                    class="form-control mr-input sm"
                    formControlName="offline_recording_url"
                    type="text"
                    name="offline_recording_url"
                    value=""
                    placeholder="Enter Recording URL"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      meetingForm.get('offline_recording_url').invalid &&
                      meetingForm.get('offline_recording_url').touched
                    "
                  >
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        meetingForm.get('offline_recording_url').errors[
                          'required'
                        ]
                      "
                    >
                      Recording Url is mandatory
                    </div>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        meetingForm.get('offline_recording_url').errors[
                          'pattern'
                        ]
                      "
                    >
                      URL pattern is wrong
                    </div>
                  </div>
                </div>

                <div class="participants-selection-block">
                  <div class="participants-title">Participants</div>
                  <div class="participants-selection-row">
                    <div class="ps-col">
                      <div class="form-group mr-context-menu-wrp">
                        <label
                          >Teammates&nbsp;<span
                            *ngIf="
                              meetingForm.get('teammates')?.value?.length > 0
                            "
                            >({{
                              meetingForm.get('teammates')?.value?.length
                            }})</span
                          >
                          <!-- <span class="check-availability"
                          >Check Availability</span -->
                        </label>
                        <participant-dropdown
                          [disabledElement]="isEditFormDisabled"
                          formControlName="teammates"
                          [addElementOnInput]="true"
                          [dynamicUserSuggestions]="teammatesDynamicSuggestions"
                          [staticUserSuggestions]="teammatesStaticSuggestions"
                          (searchDynamicSuggestions)="searchTeamMates($event)"
                          placeholder="Teammates name or email"
                          [allowedEmailSuffix]="
                            teammatesStaticSuggestions.allowedEmailSuffix
                          "
                        ></participant-dropdown>
                      </div>
                    </div>
                    <div class="ps-col">
                      <div class="form-group mr-context-menu-wrp">
                        <label
                          >External&nbsp;<span
                            *ngIf="
                              meetingForm.get('external')?.value?.length > 0
                            "
                            >({{
                              meetingForm.get('external')?.value?.length
                            }})</span
                          ></label
                        >
                        <participant-dropdown
                          [disabledElement]="isEditFormDisabled"
                          formControlName="external"
                          [addElementOnInput]="true"
                          [staticUserSuggestions]="externalSuggestions"
                          placeholder="Client name or email"
                          [disAllowedEmailSuffix]="
                            externalSuggestions.disAllowedEmailSuffix
                          "
                        ></participant-dropdown>
                      </div>
                    </div>
                    <div class="ps-col">
                      <div class="form-group mr-context-menu-wrp">
                        <label
                          >Experts&nbsp;<span
                            *ngIf="
                              meetingForm.get('experts')?.value?.length > 0
                            "
                            >({{
                              meetingForm.get('experts')?.value?.length
                            }})</span
                          ></label
                        >
                        <participant-dropdown
                          [disabledElement]="isEditFormDisabled"
                          formControlName="experts"
                          [staticUserSuggestions]="expertSuggestions"
                          placeholder="Expert's name or email"
                          fieldType="Expert"
                        ></participant-dropdown>
                      </div>
                    </div>
                  </div>
                  <div
                    style="margin-top: -5px"
                    class="invalid-feedback"
                    *ngIf="meetingForm.touched && participants.length === 0"
                  >
                    You need to add at least one participant
                  </div>
                </div>
              </ng-container>
            </div>

            <div *ngIf="isBMeetPlatform" class="meeting-agenda-attachments-col">
              <div
                class="col-editor"
                [ngClass]="{ 'full-height': !isMOMSectionVisible() }"
              >
                <div class="col-title">Agenda and attachments</div>
                <div class="agenda-attachment-wrp">
                  <div
                    class="top-agenda-content agenda-editor-nopadding"
                    (keydown.enter)="$event.stopPropagation()"
                    [ngClass]="{
                      disabled: isEditFormDisabled && !isKickOffCall
                    }"
                    [elemDetect]="agendaConfig.showToolbar"
                    (elemDetectChange)="blurEditor('agenda')"
                    (click)="focus('agenda')"
                  >
                    <custom-angular-editor
                      [placeholder]="'Add the agenda of the meeting...'"
                      [config]="agendaConfig"
                      formControlName="meetingAgenda"
                      class="activeToolbar"
                      (click)="editorLink($event)"
                      (focus)="focus('agenda')"
                      (blur)="blurEditor('agenda')"
                    ></custom-angular-editor>
                  </div>
                  <!-- <div class="top-agenda-content">
                  <div class="agenda-content">
                    Add the agenda of the meeting...
                  </div>
                  <div class="natasha-agenda-btn">
                    <em class="icon"
                      ><img
                        src="../../../../../assets/images/natasha-small.svg"
                        alt=""
                    /></em>
                    Write with Natasha
                  </div>
                </div> -->
                  <div class="attachment-wrp">
                    <div class="left-attachments">
                      <div class="attachments-list">
                        <div
                          class="attachments-list-col"
                          *ngFor="let file of filesToBeUpload; let i = index"
                        >
                          <div class="attachment-card">
                            <em class="thumb"
                              ><img
                                src="../../../../../assets/images/notes-icon.svg"
                                alt=""
                            /></em>
                            <div class="attachment-content">
                              <div class="file-name">
                                {{
                                  file.name ? file.name : file.file_identifier
                                }}
                              </div>
                              <div class="file-size">
                                {{
                                  file.size
                                    ? convertFileSize(file.size)
                                    : file.file_size
                                }}
                              </div>
                            </div>
                            <div class="attachment-menu mr-context-menu-wrp">
                              <div
                                class="attachment-trigger"
                                [ngClass]="{
                                  active: isAttachmentMenuClickedItem
                                }"
                                (click)="
                                  openDeleteOption(i); $event.stopPropagation()
                                "
                              >
                                <img
                                  src="../../../../../assets/images/vertical-dots-black.svg"
                                  alt=""
                                />
                              </div>
                              <div
                                class="mr-context-menu abs-pos delete-menu"
                                *ngIf="isAttachmentMenuClickedItem === i"
                              >
                                <div
                                  class="context-menu-item"
                                  (click)="removeFile(i)"
                                >
                                  Delete
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="attachments-list-col">
                        <div class="attachment-card">
                          <em class="thumb"
                            ><img
                              class="img-preview"
                              src="../../../../../assets/images/v1/yash-dp.jpg"
                              alt=""
                          /></em>
                          <div class="attachment-content">
                            <div class="file-name">File name.png</div>
                            <div class="file-size">128 KB</div>
                          </div>
                          <div class="attachment-menu mr-context-menu-wrp">
                            <div
                              class="attachment-trigger"
                              [ngClass]="{ active: attachmentMenuIsOpen }"
                              (click)="
                                attachmentMenuIsOpen = !attachmentMenuIsOpen
                              "
                            >
                              <img
                                src="../../../../../assets/images/vertical-dots-black.svg"
                                alt=""
                              />
                            </div>
                            <div
                              class="mr-context-menu abs-pos"
                              *ngIf="attachmentMenuIsOpen"
                            >
                              <div class="context-menu-item">Delete</div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      </div>
                    </div>
                    <div class="btn-wrp">
                      <div
                        class="green-btn is-lightgrey icon-btn attachment-btn"
                        [ngClass]="{
                          disabled: isEditFormDisabled
                        }"
                      >
                        <input
                          tabindex="-1"
                          type="file"
                          class="file-input"
                          multiple
                          (change)="onChange($event)"
                        />
                        <img
                          src="../../../../../assets/images/attachment-black.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="meetingAgenda.touched && meetingAgenda.invalid"
                >
                  Meeting Agenda is mandatory
                </div>
              </div>
              <div class="col-editor" *ngIf="isMOMSectionVisible()">
                <div class="col-title">Minutes Of Meetings</div>
                <div
                  class="agenda-attachment-wrp"
                  [elemDetect]="momConfig.showToolbar"
                  (elemDetectChange)="blurEditor('mom')"
                  (click)="focus('mom')"
                >
                  <!-- [ngStyle]="{
                    height: meetingKind == 'offline' ? '50%' : '100%'
                  }" -->
                  <div
                    class="top-agenda-content agenda-editor-nopadding"
                    (keydown.enter)="$event.stopPropagation()"
                  >
                    <custom-angular-editor
                      [placeholder]="'Add MoM of the meeting...'"
                      [config]="momConfig"
                      formControlName="meetingMinutes"
                      class="activeToolbar"
                      (click)="editorLink($event)"
                      (focus)="focus('mom')"
                      (blur)="blurEditor('mom')"
                    ></custom-angular-editor>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngTemplateOutlet="zoomTemplate"></ng-container>

          <div class="custom-modal-btns">
            <button
              *ngIf="isBMeetPlatform"
              class="green-btn"
              [disabled]="
                (editMeeting?.studio_call_type !== 'kickoff' &&
                  (meetingDate.touched || startTimeTouched) &&
                  !isStartTimeValid) ||
                !(
                  isEndTimeValid
                  | applyFunc
                    : meetingDate?.value?.singleDate?.jsDate
                    : meetingForm.get('meetingStartTime')?.value
                    : meetingForm.get('meetingEndTime')?.value
                    : timezoneConfig?.selectedData
                  | async
                )?.value ||
                meetingForm.invalid ||
                participants.length === 0 ||
                (meetingForm.get('meetingRecurrenceType')?.value !== 'single' &&
                  recurOccurences.length === 0)
              "
            >
              Save
            </button>
            <button
              *ngIf="!isBMeetPlatform"
              class="green-btn"
              (click)="okClicked()"
            >
              Ok
            </button>
            <button
              class="primary-purple-btn is-outlined"
              (click)="closeMeetingModal()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #zoomTemplate>
  <div class="zoom-container" *ngIf="!isBMeetPlatform">
    <div class="zoom-header">Guidelines for scheduling a zoom meeting</div>
    <ol class="zoom-message">
      <li>
        Use the Zoom web platform if you need to schedule a recurring meeting.
      </li>
      <li>
        Please format the meeting name as follows:
        <br /><strong>[#projectID] meeting_name</strong> <br />where projectID
        is the ID from your Builder360 project URL.
      </li>
    </ol>
    <div class="zoom-image">
      Example:
      <div class="img-block">
        <img
          src="assets/images/zoom-recording.svg"
          class="zoom-img"
          alt="zoom-recording-helper"
        />
      </div>
    </div>
  </div>
</ng-template>
