import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ActionCableService, Channel } from 'angular2-actioncable';
import {
  Observable,
  Subject,
  Subscription,
  filter,
  interval,
  of,
  switchMap,
  take,
  takeUntil
} from 'rxjs';
import { UAParser } from 'ua-parser-js';
import { environment } from '../../../../environments/environment';
import { AuthGuard } from '../../../auth-guard.service';
import { WarningService } from '../../../core/services/warning.service';
import { ApiRequests } from '../../../shared/shared-module/services/apiRequests.service';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { CustomAnimations } from '../../../shared/shared-module/utility/animations';
import { ProjectStatusService } from '../../services/project-status.service';
import { NotificationCenterRequestActionType } from '../notification-center/enum/request-action';
import { NotificationCenterResponseActionType } from '../notification-center/enum/response-action';
import { NotificationCenterComponent } from '../notification-center/notification-center.component';
import { NotificationCenterService } from '../notification-center/services/notification-center.service';
import { Constants } from '../../../shared/shared-module/utility/constants';

@Component({
  selector: 'app-main-leftpanel',
  templateUrl: './main-leftpanel.component.html',
  styleUrls: ['./main-leftpanel.component.scss'],
  animations: [
    CustomAnimations.flyInOutAnimation.slideInLeft,
    trigger('fadeInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          transform: 'scale(0.7)'
        }),
        animate(
          100,
          style({
            opacity: '1',
            transform: 'scale(1)'
          })
        )
      ]),
      transition(':leave', [
        animate(
          100,
          style({
            opacity: '0',
            transform: 'scale(0.7)'
          })
        )
      ])
    ])
  ]
})
export class MainLeftpanelComponent implements OnInit, OnDestroy {
  public currentSelection = '/projects';
  isPartnersShow = true;
  loginData;
  params = {
    search: '',
    page: 1,
    perPage: 20
  };
  isShowPaginationLoader = false;
  searchText = '';
  projectSubscription: Subscription;
  projects = [];
  totalCount = 0;
  @ViewChild('searchBuildCardSidebar', { static: false })
  searchBuildCardSidebar: ElementRef;
  @ViewChild('searchBox', { static: false })
  searchBox: ElementRef;
  mobileMenu = false;
  isForceRedirect = false;
  subscription = new Subject();
  bchatNotificationCount = 0;
  bchatWebSocket: Channel;
  myProjectDetailSubscription;
  isStudio4BuildCard = false;
  notifyProjectSub: Subscription;
  project_detail;
  notificationsVisible = false;
  takeUntilSubscription = new Subject();
  notiificationCenterWebSocketChannel: Channel;
  newNotifications$;
  newNotificationsArr = [];

  notificationPage$: Observable<number>;
  notificationIsLoading$: Observable<boolean>;
  unseenCount = 0;
  @ViewChild(NotificationCenterComponent)
  notificationPanel!: NotificationCenterComponent;

  constructor(
    public projectStatusService: ProjectStatusService,
    public apiRequests: ApiRequests,
    public router: Router,
    public commonService: CommonService,
    public cdr: ChangeDetectorRef,
    public dataService: DataService,
    public authGuard: AuthGuard,
    public warningService: WarningService,
    public cableService: ActionCableService,
    public notificationCenterService: NotificationCenterService
  ) {
    const partnerData = JSON.parse(localStorage.getItem('partnerData'));
    if (partnerData && !partnerData.vendor_backend_domain) {
      this.isPartnersShow = false;
    }

    this.notificationCenterService
      .getNotificationPopupData()
      .subscribe((items) => {
        for (const item of items) {
          for (const noti of item.value) {
            if (!this.newNotificationsArr.some((el) => noti.id === el.id)) {
              this.newNotificationsArr.push(noti);
            }
          }
        }
      });

    interval(5000)
      .pipe(
        switchMap(() =>
          this.newNotificationsArr.length > 0
            ? of(this.newNotificationsArr.shift())
            : of(null)
        )
      )
      .subscribe((removedItem) => {
        if (removedItem !== null) {
          console.log('removedItem =', removedItem);
          // Optional: Update UI or perform other actions after removal
        }
      });

    this.notificationPage$ = this.notificationCenterService.getPage();
    this.notificationIsLoading$ = this.notificationCenterService.getIsLoading();
    this.notificationCenterService
      .getUnseenNotificationsCount()
      .subscribe((count) => {
        this.unseenCount = count;
      });
  }

  ngOnInit() {
    this.myProjectDetailSubscription =
      this.commonService.notifyProjectDetailSubject.subscribe((data) => {
        if (data) {
          this.project_detail = data;
          if (data?.studio_version == 2) {
            this.isStudio4BuildCard = true;
          } else {
            this.isStudio4BuildCard = false;
          }
        }
      });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = event.url;
        if (url.startsWith('/') && url.split('/').length > 1) {
          url = '/' + url.split('/')[1];
        }

        if (url && url.includes('/projects')) {
          if (!url.includes('/project-allocation')) {
            localStorage.removeItem('projectAllocationTab');
          }
          url = '/projects';
        }

        if (url !== '/projects') {
          this.commonService.leftPanelOpen = false;
          this.commonService.showHomePage = true;
        }
        this.currentSelection = url;
      }

      setTimeout(() => {
        this.isForceRedirect = !!sessionStorage.getItem('force_redirection');
      }, 100);
    });
    this.loginData = this.getUserData();
    if (!this.loginData) {
      this.commonService.newHeadertrue = true;
      return;
    } else {
      this.commonService.newHeadertrue = false;
    }
    this.checkAuthUrl();

    this.inItBchatWebSocket();

    this.notifyProjectSub =
      this.commonService.notifyProjectDetailSubject.subscribe((data) => {
        if (data) {
          this.project_detail = data;
        }
      });

    this.inItNotificationWebSocket();
  }

  public changeSection(sectionName) {
    this.notificationsVisible = false;
    if (sectionName === '/repository') {
      this.router.navigate(['/repository/folder']);
    } else {
      this.router.navigate([sectionName]);
    }
    let { id, designation } = this.dataService.getUserData(); // eslint-disable-line
    const parser = new UAParser();
    const userBrowser = parser.getBrowser();
    const userDevice = parser.getDevice();
    const prop = {
      user_designation: designation,
      user_id: id,
      user_browser: userBrowser,
      user_device: userDevice
    };
    if (sectionName == '/projects') {
      this.commonService.trackSegmentEventWithBrowserAndUserInfo(
        `buildcards_clicked`,
        this.dataService.getUserData(),
        '',
        null
      );
    } else if (sectionName == '/managers') {
      this.commonService.trackSegmentEventWithBrowserAndUserInfo(
        Constants.eventAndDeweyCodes.managers_clicked.eventName,
        this.dataService.getUserData(),
        Constants.eventAndDeweyCodes.managers_clicked.deweyCode,
        null,
        prop
      );
    } else if (sectionName == '/spec-calls') {
      this.commonService.trackSegmentEventWithBrowserAndUserInfo(
        Constants.eventAndDeweyCodes.studio_call_clicked.eventName,
        this.dataService.getUserData(),
        Constants.eventAndDeweyCodes.studio_call_clicked.deweyCode,
        null,
        prop
      );
    } else if (sectionName == '/partners') {
      this.commonService.trackSegmentEventWithBrowserAndUserInfo(
        Constants.eventAndDeweyCodes.partners_clicked.eventName,
        this.dataService.getUserData(),
        Constants.eventAndDeweyCodes.partners_clicked.deweyCode,
        null,
        prop
      );
    } else if (sectionName == '/accounts') {
      this.commonService.trackSegmentEventWithBrowserAndUserInfo(
        Constants.eventAndDeweyCodes.accounts_clicked.eventName,
        this.dataService.getUserData(),
        Constants.eventAndDeweyCodes.accounts_clicked.deweyCode,
        null
      );
    } else if (sectionName == '/repository') {
      this.commonService.trackSegmentEventWithBrowserAndUserInfo(
        Constants.eventAndDeweyCodes.repository_clicked.eventName,
        this.dataService.getUserData(),
        Constants.eventAndDeweyCodes.repository_clicked.deweyCode,
        null
      );
    } else if (sectionName == '/catalogue') {
      this.commonService.trackSegmentEventWithBrowserAndUserInfo(
        Constants.eventAndDeweyCodes.catalogue_clicked.eventName,
        this.dataService.getUserData(),
        Constants.eventAndDeweyCodes.catalogue_clicked.deweyCode,
        null
      );
    } else if (sectionName == '/bchat') {
      this.commonService.trackSegmentEventWithBrowserAndUserInfo(
        `bchat_clicked`,
        this.dataService.getUserData(),
        '',
        null
      );
    } else if (sectionName == '/configuration') {
      this.commonService.trackSegmentEventWithBrowserAndUserInfo(
        `configuration_clicked`,
        this.dataService.getUserData(),
        '',
        null
      );
    }
    this.projectStatusService.projectTabStatus = 'paid';
    // this.commonService.leftPanelOpen = false;
    // this.commonService.showHomePage = true;
    // this.currentSelection = sectionName;
    if (sectionName === '/partners') {
      this.commonService.notifyPartnersListDetail(true);
    }

    this.mobileMenu = false;
    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        take(1)
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url && !event.url.includes('/projects/')) {
          this.currentSelection = sectionName;
          this.commonService.leftPanelOpen = false;
          this.commonService.showHomePage = true;
        }
      });
  }

  public getUserData() {
    return this.dataService.user;
  }

  onScroll() {
    if (this.params.page !== null && !this.isShowPaginationLoader) {
      this.getProjects('scroll');
    }
  }

  getProjects(data, search?) {
    this.params.search = this.searchText;
    this.isShowPaginationLoader = true;
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    this.projectSubscription = this.apiRequests
      .getProjectsName(this.params)
      .subscribe(
        (res) => {
          if (search) {
            this.projects = [];
          }
          if (this.searchText?.trim() === '') {
            this.projects = [];
            this.totalCount = 0;
            this.params.page = 1;
          } else {
            if (data === 'scroll') {
              this.projects = this.projects.concat(res.projects);
            } else {
              this.projects = res.projects;
            }
            this.params.page = res.next_page;
            this.totalCount = res.total_count;
          }
          this.isShowPaginationLoader = false;
        },
        () => {
          this.isShowPaginationLoader = false;
        }
      );
  }

  getSearchProjects() {
    this.params.page = 1;
    this.totalCount = 0;
    if (this.searchText.length > 1) {
      this.getProjects('', true);
    }
  }

  openSideBar(event) {
    this.notificationsVisible = false;
    event.stopPropagation();
    event.preventDefault();
    this.projects = [];
    this.searchText = '';
    this.projectStatusService.openProjectDD();
    this.cdr.detectChanges();
    setTimeout(() => {
      if (this.searchBox) {
        this.searchBox.nativeElement.focus();
      }
    }, 100);
  }

  projectLinkClick(id) {
    setTimeout(() => {
      this.projectStatusService.closeProjectDD();
    }, 500);
    this.commonService.selectedProjecId = id;
    this.router.navigate([
      '/projects/' + id + '/basic-information/' + 'project-detail'
    ]);
    this.commonService.leftPanelOpen = true;
    this.commonService.showHomePage = false;
    this.mobileMenu = false;
    setTimeout(() => {
      this.projectStatusService.closeProjectDD();
    }, 500);
  }

  @HostListener('document:click', ['$event'])
  public documentClickListner($event) {
    if (
      this.projectStatusService.openProDD &&
      this.searchBuildCardSidebar &&
      !this.searchBuildCardSidebar.nativeElement.contains($event.target)
    ) {
      {
        this.projectStatusService.closeProjectDD();
      }
    }
  }

  mobileMenuClick() {
    this.mobileMenu = !this.mobileMenu;
  }

  ngOnDestroy() {
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    this.takeUntilSubscription.next(null);
    this.takeUntilSubscription.complete();
    this.subscription.next(null);
    this.subscription.complete();
    if (this.notifyProjectSub) {
      this.notifyProjectSub.unsubscribe();
    }
  }

  checkAuthUrl() {
    if (
      this.authGuard.inputedURL == '/projects' ||
      this.authGuard.inputedURL == '/partners' ||
      this.authGuard.inputedURL == '/spec-calls' ||
      this.authGuard.inputedURL == '/managers' ||
      this.authGuard.inputedURL == '/accounts' ||
      this.authGuard.inputedURL == '/repository/folder'
    ) {
      this.currentSelection = this.authGuard.inputedURL;
    }
  }

  getBchatWebSocketChannel() {
    return this.cableService
      .cable(this.dataService.getBChatWebScocketUrl())
      .channel('NotificationsChannel', {
        user_email: this.dataService.getUserEmail()
      });
  }

  inItBchatWebSocket() {
    this.bchatWebSocket = this.getBchatWebSocketChannel();

    this.bchatWebSocket
      .received()
      .pipe(takeUntil(this.takeUntilSubscription))
      .subscribe((message) => {
        if (message?.notifications_count) {
          this.bchatNotificationCount = message.notifications_count;
        } else {
          this.bchatNotificationCount = 0;
        }
      });
  }

  getNotificationCenterWebSocketChannel() {
    return this.cableService
      .cable(
        `${
          environment.NOTIFICATION_NEXUS_URL
        }?token=${this.notificationCenterService.generateJwtToken()}`
      )
      .channel('NotificationChannel');
  }

  inItNotificationWebSocket() {
    this.notiificationCenterWebSocketChannel =
      this.getNotificationCenterWebSocketChannel();

    this.notiificationCenterWebSocketChannel
      .received()
      .pipe(takeUntil(this.takeUntilSubscription))
      .subscribe((message) => {
        this.notificationCenterService.setWebSocketStateByType(message);
        this.broadcastForAllRead(message);
      });
  }

  broadcastForAllRead(message) {
    if (message.type === NotificationCenterResponseActionType.Mark_All_Read) {
      this.notiificationCenterWebSocketChannel.send({
        activity: NotificationCenterRequestActionType.Get_All_Notifications,
        page: 1,
        perPage: 20
      });
      this.notiificationCenterWebSocketChannel.send({
        activity: NotificationCenterRequestActionType.Get_Unread_Notifications,
        page: 1,
        perPage: 20
      });
    }
  }

  performNotificationSocketAction(action) {
    this.notiificationCenterWebSocketChannel.send({
      activity: action.type,
      ...action.data
    });
  }

  closeNotificationPopup() {
    this.notificationCenterService.setNotificationPopupData(null);
  }

  toggleNotificationBar() {
    this.notificationsVisible = !this.notificationsVisible;
  }

  removeElement(element) {
    const index = this.newNotificationsArr.findIndex(
      (item) => item.id === element.id
    );
    if (index > -1) {
      const removedItem = this.newNotificationsArr.splice(index, 1)[0]; // Remove the item and get it
      console.log('removedItem = ', removedItem);
      // Optional: Update UI or perform other actions after removal
    }
  }

  removeAllElement() {
    this.newNotificationsArr = [];
  }

  toggleNotificationPanel(event: Event) {
    event.stopPropagation();

    this.notificationsVisible = !this.notificationsVisible;

    if (this.notificationsVisible) {
      const closeSidebar = (clickEvent: MouseEvent) => {
        const sidebarElement =
          this.notificationPanel.notificationWrapper.nativeElement;

        if (
          sidebarElement &&
          !sidebarElement.contains(clickEvent.target as Node)
        ) {
          this.notificationsVisible = false;
          document
            .querySelector('body')
            .removeEventListener('click', closeSidebar);
        }
      };

      setTimeout(() => {
        document.querySelector('body')?.addEventListener('click', closeSidebar);
      }, 0);
    }
  }
}
