import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequests } from './http-requests.service';
import { Constants } from '../utility/constants';
import { DataService } from './data.service';
import { CallScoreService } from './call-score.service';
import { catchError, map } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { jsonwebtokenDecrypt } from '../utility/utility.functions';

@Injectable()
export class ApiRequests {
  constructor(
    public httpRequest: HttpRequests,
    public dataService: DataService,
    public callScoreService: CallScoreService,
    private http: HttpClient
  ) {}

  public login_signup(userdetails, option) {
    let API;
    if (option === 'login') {
      API = this.dataService.getServerUrl() + Constants.apiEndPoints.login;
      if (window.location.href.indexOf('invited') > -1) {
        const queryParam = window.location.href.split('?');
        API =
          this.dataService.getServerUrl() +
          Constants.apiEndPoints.login +
          '?' +
          queryParam[1];
      }
    } else if (option === 'signup') {
      API = this.dataService.getServerUrl() + Constants.apiEndPoints.signup;
    } else if (option === 'forgot') {
      API = this.dataService.getServerUrl() + Constants.apiEndPoints.forgot;
    } else if (option === 'reset') {
      API = this.dataService.getServerUrl() + Constants.apiEndPoints.reset;
    }
    if (userdetails.email) {
      userdetails.email = userdetails.email.toLowerCase();
    }
    return this.httpRequest
      .post(
        API,
        JSON.stringify(userdetails),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => {
          if (
            option === 'forgot' ||
            option === 'reset' ||
            option === 'signup'
          ) {
            return res;
          } else {
            this.dataService.user = res.data.user;
            this.dataService.authToken = res.data['auth-token'];
            this.dataService.storeUserObject();
            return res;
          }
        }),
        catchError((error) => {
          this.dataService.user = null;
          return throwError(() => error);
        })
      );
  }

  public updateUserDetails(userdetails) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.update_user_details +
      this.dataService.user.id;
    return this.httpRequest
      .patch(
        API,
        JSON.stringify(userdetails),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => {
          this.dataService.user = res.data.user;
          this.dataService.storeUserObject();
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public uploadProfilePic(profilePic) {
    const data = new FormData();
    data.append('user[profile_pic]', profilePic);
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.update_user_details +
      this.dataService.user.id;
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          this.dataService.user = res.data.user;
          this.dataService.storeUserObject();
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public changePassword(userdetails) {
    const API = this.dataService.getServerUrl() + Constants.apiEndPoints.cred;
    return this.httpRequest
      .post(
        API,
        JSON.stringify(userdetails),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getUserData() {
    this.dataService.user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null;
    return this.dataService.user;
  }

  public getUpdatedUserData() {
    if (this.dataService.user == null) {
      return throwError(() => 'Please try again!');
    }
    const API =
      this.dataService.getServerUrl() + 'users/' + this.dataService.user.id;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          this.dataService.user = res.data.user;
          if (res.data.user.pending_spec_rating) {
            if (
              this.callScoreService.callScoreData.length !==
              res.data.user.pending_spec_rating
            ) {
              this.callScoreService.callScoreData =
                res.data.user.pending_spec_rating;
              this.callScoreService.notifyCallScore(
                res.data.user.pending_spec_rating
              );
            }
            this.callScoreService.showCallScorePopup = true;
          }
          localStorage.setItem('user', JSON.stringify(res.data.user));
          this.dataService.isUserUpdated.next(true);
          localStorage.setItem('authToken', this.dataService.authToken);
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getAuthToken() {
    this.dataService.authToken = localStorage.getItem('authToken')
      ? localStorage.getItem('authToken')
      : '';
  }

  fetchPartnerDetails() {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.fetch_partners_details;
    return this.httpRequest.get(API).pipe(
      map((res) => {
        this.dataService.partnerData = res.data.partner;
        this.dataService.projectData = res.data.platform_details;
        this.dataService.releaseStatusList = res.data.release_status;
        this.dataService.z_stub = res.data.z_stub;
        this.dataService.bugFix45DayWindow = res.data.bug_fix_prescribed_date;
        localStorage.setItem('partnerData', JSON.stringify(res.data.partner));
        localStorage.setItem(
          'platformDetails',
          JSON.stringify(res.data.platform_details)
        );
        localStorage.setItem(
          'release_status',
          JSON.stringify(res.data.release_status)
        );
        localStorage.setItem(
          'bug_fix_prescribed_date',
          res.data.bug_fix_prescribed_date
        );
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  logoutSession() {
    const API = this.dataService.getServerUrl() + 'sessions';
    return this.httpRequest
      .delete(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  createDates(dates) {
    const API =
      this.dataService.getServerUrl() +
      'users/' +
      this.dataService.user.id +
      '/available_dates';
    return this.httpRequest
      .post(
        API,
        dates,
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  updateData(
    id,
    startTime?,
    endTime?,
    date?,
    editId?,
    delData?,
    automated_slot?
  ) {
    let available_date;
    if (startTime && endTime && !editId && !delData) {
      available_date = {
        available_date: {
          time_slots_attributes: [
            {
              from: startTime,
              to: endTime
            }
          ]
        }
      };
    } else if (startTime && automated_slot && editId && !delData) {
      /*For automated slot not sending end time*/
      available_date = {
        available_date: {
          time_slots_attributes: [
            {
              id: editId,
              from: startTime
            }
          ]
        }
      };
    } else if (startTime && endTime && editId && !delData) {
      available_date = {
        available_date: {
          time_slots_attributes: [
            {
              id: editId,
              from: startTime,
              to: endTime
            }
          ]
        }
      };
    }
    if (delData) {
      available_date = delData;
    }

    if (date) {
      available_date = date;
    }
    const API =
      this.dataService.getServerUrl() +
      'users/' +
      this.dataService.user.id +
      '/available_dates/' +
      id;
    return this.httpRequest
      .patch(API, available_date, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public deleteCal(id) {
    const API =
      this.dataService.getServerUrl() +
      'users/' +
      this.dataService.user.id +
      '/available_dates/' +
      id;
    return this.httpRequest
      .delete(API, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getUserTimeZoneList() {
    const API =
      this.dataService.getServerUrl() + Constants.apiEndPoints.timezone;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getClientByClientId(id) {
    const API =
      this.dataService.getServerUrl() +
      'projects/' +
      id +
      '/' +
      Constants.apiEndPoints.client;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getProjectAllocatedExperts(projectId) {
    const API = `${this.dataService.getServerUrl()}${Constants.apiEndPoints.getProjectAllocatedExperts.replace(
      '[project_id]',
      projectId
    )}`;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getSpecIDStudio(projectData, studioCallData?) {
    const API =
      this.dataService.getServerUrlForAccounts(projectData) +
      'pm_dashboard/create_spec_call';
    const data = studioCallData;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );

    return this.httpRequest.post(API, data, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  getProjectsName(params) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.getSidebarProjects +
      '?page=' +
      params.page +
      '&per_page=' +
      params.perPage +
      '&timezone=' +
      Intl.DateTimeFormat().resolvedOptions().timeZone +
      (params.search ? `&search=${params.search}` : '') +
      this.dataService.forceRedirect(true);

    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getUserDetailFromBMeet() {
    const API =
      this.dataService.getBMeetURL() +
      'users/profile?email=' +
      this.dataService.getUserData()?.email;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'Content-Type',
      'application/json'
    );
    httpOptions.headers = httpOptions.headers.set(
      'appToken',
      this.dataService.getBMeetToken()
    );

    return this.httpRequest.getWithoutCheckErrorStatus(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => throwError(() => error))
    );
  }

  deleteTimeSlots(id, rangeID) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.deleteTimeSlot
        .replace('[user_id]', this.dataService.user.id)
        .replace('[available_dates_id]', rangeID)
        .replace('[time_slots_id]', id);
    return this.httpRequest
      .delete(API, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  deleteDateRange(dateRangeId) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.deleteDateSlot
        .replace('[user_id]', this.dataService.user.id)
        .replace('[available_dates_id]', dateRangeId);
    return this.httpRequest
      .delete(API, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  updateUsersAvailability(payload) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.updateDateSlot.replace(
        '[user_id]',
        this.dataService.user.id
      );
    return this.httpRequest
      .patch(API, payload, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getProjectDetailById(projectId, forceRedirect = false) {
    let API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.getProjectDetail.replace(
        '[project_id]',
        projectId
      );

    if (forceRedirect) {
      API += '?forceid=' + projectId;
    }

    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getManagers(page?, perPage?, query?, value?, min?) {
    let API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.manager +
      '?page=' +
      page +
      '&per_page=' +
      perPage;

    if (query) {
      API = API + '&search=' + query;
    }
    if (value) {
      API =
        API +
        '&filter=status' +
        '&value=' +
        value +
        '&status[]=' +
        this.dataService.getPartnerData()?.id +
        '_false';
    }

    if (min) {
      API = API + '&min=' + min;
    }

    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getRefreshZoomToken() {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.getRefreshZoomToken;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data?.token) {
            return jsonwebtokenDecrypt(res.data.token);
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getPods(page?, perPage?, query?, value?, min?, withProjects = true) {
    let API = `${this.dataService.getServerUrl()}${
      Constants.apiEndPoints.getAllPods
    }?with_projects=${withProjects}`;
    if (page && typeof page == 'number') {
      API += `&page=${page}`;
    }

    if (perPage && typeof perPage == 'number') {
      API += `&per_page=${perPage}`;
    }

    if (query) {
      API = `${API}&search=${query}`;
    }
    if (value) {
      API = `${API}&filter=status&value=${value}`;
    }

    if (min) {
      API = `${API}&min=${min}`;
    }

    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  public getPod(id) {
    const API = `${this.dataService.getServerUrl()}${
      Constants.apiEndPoints.getAllPods
    }/${id}`;

    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  public getTodosByIds(projectId: string, ids: number[]): Observable<any> {
    let API = `${this.dataService.getServerUrl()}${Constants.apiEndPoints.getTodosByIds.replace(
      '[projectId]',
      projectId
    )}`;

    let queryString = '';
    ids.forEach((id, index) => {
      queryString += `ids[]=${id}`;
      if (index < ids.length - 1) {
        queryString += '&';
      }
    });

    API += `?${queryString}`;

    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  getPrimaryMembersByProjectId(projectIds: number[]) {
    const API =
      `${this.dataService.getServerUrl()}${Constants.apiEndPoints.projectPrimaryUsers}?` +
      projectIds.map((projectId) => `project_ids[]=${projectId}`).join('&');

    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }
}
